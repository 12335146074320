import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import TriangleBlur from "../style/Header/triangleBlur";
import { Container, Flex, Wrapper } from "../style/Grid";
import { Heading } from "../style/Heading";
import { Text } from "../style/Text";
import Header from "../components/Header";
import Bubble from "../components/Bubble";
import TileButton from "../components/TileButton";
import { URL } from "../constants";
import usePageView from "../utils/usePageView";
import SEO from "../components/seo";

const TeamPostTemplate = props => {
  usePageView(props.location.pathname, "O EY", "Kolega");

  const post = props.data.mdx;
  const postData = post.frontmatter;
  const siteTitle = props.data.site.siteMetadata.title;
  const { previous, next } = props.pageContext;

  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO title={postData.name} description={postData.workingDay} />
      <Header
        bgImage={postData.headerPersonImage}
        backgroundPosition="top center"
        minHeight={["430px", null, "650px", null, null]}
        height={"26vw"}
        bottomComponents={<TriangleBlur />}
      >
        <Flex flexDirection="column" justifyContent="flex-end" height="100%">
          <Heading
            as="h1"
            alignSelf="bottom"
            color="white.100"
            mb={3}
            fontSize={[4, null, 5, 6, null]}
            fontWeight="light"
            lineHeight="3"
            maxWidth={["220px", null, "500px", null, null]}
            backgroundPosition="center center"
          >
            {postData.name}
          </Heading>
          <Text
            as="p"
            maxWidth="450px"
            color="white.100"
            fontWeight="light"
            fontSize={2}
          >
            {postData.role}
          </Text>
          <Text
            as="p"
            maxWidth="450px"
            color="white.100"
            fontWeight="light"
            mb={80}
            fontSize={2}
          >
            {postData.department}
          </Text>
        </Flex>
      </Header>
      <Wrapper as="section" bg="EYOffBlack.default" overflow="hidden">
        <Container
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center"
          mb={["20px", "30px", null, "40px", null]}
        >
          <Bubble
            left={true}
            light={false}
            wide={true}
            description={postData.workingDay}
            name={postData.name}
            position={postData.role}
            department={postData.department}
            image={
              postData.profilePersonImage &&
              postData.profilePersonImage
            }
            heading="Co tě na tvé práci baví?"
          />
        </Container>
      </Wrapper>
      <Wrapper as="section" bg="white.100" overflow="hidden">
        <Container
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center"
          mt={["40px", null, null, "100px", null]}
          mb={["20px", "40px", null, "50px", null]}
        >
          <Bubble
            left={false}
            light={true}
            wide={true}
            description={postData.story}
            name={postData.name}
            position={postData.role}
            department={postData.department}
            image={
              postData.profilePersonImage &&
              postData.profilePersonImage
            }
            heading="Co ti tvoje práce dala?"
          />

          <Bubble
            left={true}
            light={true}
            wide={true}
            description={postData.whyWork}
            name={postData.name}
            position={postData.role}
            department={postData.department}
            image={
              postData.profilePersonImage &&
              postData.profilePersonImage
            }
            heading={postData.whyWorkLabel}
          />
        </Container>
      </Wrapper>

      <Flex
        as="section"
        flexDirection={["column-reverse", null, null, "row", null]}
      >
        <TileButton
          href={URL.jobs}
          width={["100%", null, "100%", null, null]}
          tileColor="light"
          tileOrientation="right"
          as={"a"}
        >
          <Flex flexDirection="column" alignItems="flex-end">
            <Text
              fontSize={["18px", null, "5", null, null]}
              color="EYOffBlack.tile"
            >
              Chci se přidat k EY
            </Text>
            <Text
              fontSize={[1, null, 2, null, null]}
              fontWeight="300"
              color="EYOffBlack.tile"
              opacity=".7"
              mt={["8px", null, "24px", null, null]}
            >
              Prohlédni si volné pozice
            </Text>
          </Flex>
        </TileButton>
      </Flex>
    </Layout>
  );
};

export default TeamPostTemplate;

export const pageQuery = graphql`
  query PeoplePostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(
      fields: { slug: { eq: $slug } }
      frontmatter: { templateKey: { eq: "people-post" } }
    ) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        name
        role
        department
        headerPersonImage {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 1920, quality: 90)
          }
        }
        profilePersonImage {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 88, height: 88, quality: 90, layout: FIXED)
          }
        }
        workingDay
        story
        whyWork
        whyWorkLabel
        title
      }
    }
  }
`;
