import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { Box } from "../Grid";

const TriangleBlur = styled(Box)`
  z-index: 2;
  transform-origin: top left;
  transform: skewY(-9deg);
  background-image: linear-gradient(
    0deg,
    #2e2e38 0%,
    #2e2e38 50%,
    rgba(91, 91, 105, 0) 75%
  );

  @media screen and (min-width: ${themeGet("breakpoints.md")}) {
    transform: skewY(-3.5deg);
    background-image: linear-gradient(
      0deg,
      #2e2e38 0%,
      #2e2e38 20%,
      rgba(91, 91, 105, 0) 50%
    );
  }

  @media screen and (min-width: 1920px) {
    transform-origin: top right;
    bottom: 0;
  }
`;

TriangleBlur.defaultProps = {
  as: "span",
  position: "absolute",
  left: "0",
  width: "100%",
  height: "700px",
  bottom: ["-345px", null, "-117px", null, null],
};

export default TriangleBlur;
