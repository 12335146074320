import React from "react";
import { Image } from "../../style/Image";

import { Container } from "../../style/Grid";
import { Header as HeaderStyle } from "../../style/Header";

const Header = ({ children, bottomComponents, bgImage, ...props }) => (
  <HeaderStyle as="header" backgroundPosition="center center" {...props}>
    <Container
      flexDirection="column"
      justifyContent={
        props.containerJustifyContent ? props.containerJustifyContent : "center"
      }
      alignItems="flex-start"
      minHeight={props.minHeight}
    >
      {children}
    </Container>
    {bottomComponents}
    {bgImage?.childImageSharp ? (
      <Image
        image={bgImage}
        cover
      />
    ) : (
      <Image
        src={bgImage}
        cover
      />
    )}
  </HeaderStyle>
);

export default Header;
