import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

import { Flex } from "../Grid";

const Header = styled(Flex)`
  position: relative;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  ${props =>
    props.shadowAfter &&
    `
&:after {
    content: "";
    position: absolute;
    background-image: linear-gradient(4.2deg, rgba(46, 46, 56, 1) 0%, rgba(91, 91, 105, 0) 100%);
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    z-index: 1;
    pointer-events: none;
    transform-origin: left;
    transform: skewY(-4.2deg);
    @media screen and (min-width: 1920px) {
      transform: skewY(-3.2deg);
    }

    @media screen and (max-width: 992px) {
      background-image: linear-gradient(-4.2deg, rgba(46, 46, 56, 1) 0%, rgba(46, 46, 56, 1) 30%, rgba(91, 91, 105, 0) 100%);
      transform: skewY(0);
    }

    @media screen and (max-width: 768px) {
      background-image: linear-gradient(-8.2deg, rgba(46, 46, 56, 1) 0%, rgba(46, 46, 56, 1) 35%, rgba(91, 91, 105, 0) 100%);
      transform: skewY(0);
    }
  }
`}


${props =>
  props.shadowSimple &&
  `
&:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    z-index: 1;
    pointer-events: none;
    transform-origin: left;
    background-image: linear-gradient(-4.2deg, rgba(46, 46, 56, 1) 0%, rgba(46, 46, 56, 1) 30%, rgba(91, 91, 105, 0) 100%);
    transform: skewY(0);

    @media screen and (max-width: 768px) {
      background-image: linear-gradient(-8.2deg, rgba(46, 46, 56, 1) 0%, rgba(46, 46, 56, 1) 35%, rgba(91, 91, 105, 0) 100%);
      transform: skewY(0);
    }
  }
`}

  @media screen and (max-width: ${themeGet("breakpoints.sm")}) {
    &:after {
      display: ${props => (props.hideAfterMobile ? "none" : "block")};
    }
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 250px;
    z-index: 1;
    background-image: linear-gradient(
      180deg,
      #2e2e38 0%,
      rgba(91, 91, 105, 0) 100%
    );
    pointer-events: none;
  }

  & > div {
    position: relative;
    z-index: 10;
  }
`;

Header.defaultProps = {
  as: "header",
  backgroundPosition: "center center",
  justifyContent: "center",
};

export { Header };
