import styled from "styled-components";
import { space, color, layout, typography, position } from "styled-system";
import propTypes from "@styled-system/prop-types";

const Heading = styled.h1`
  margin: 0;
  padding: 0;
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${position}
  
`;

Heading.displayName = `Heading`;

Heading.defaultProps = {
  fontSize: 5,
  fontWeight: "normal",
};

Heading.propTypes = {
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
  ...propTypes.typography,
  ...propTypes.position,
};

export { Heading };
